<script>
    import { required } from "vuelidate/lib/validators";

    export default {
        props: ["shift", "roles", "location_id", "disableFromDate", "disableToDate", "shiftFromCalendar"],
        data() {
            return {
                days: [{ text: "Select Day", value: null }, "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                shift_names: [],
                duplicate_shift: false,
                show: true,
                locations: [],
                roles_options: [],
                isFull: true,
                chunkError:false,
                one_time: false,
                to:"05-Jan-2024 07:28 PM"
            };
        },
          validations: {
            shift: {
                name: {
                    required,
                },
                from: {
                    required,
                },
                to: {
                    required,
                },
                location_id:{
                    required,
                },
                interval: {
                    required,
                },
            },
        },

        beforeMount() {
            this.fetchLocations();
        },

        mounted() {
            this.shift.duplicate = false;
            this.shift.duplicate_status = false;
            this.fetchRoles();
            this.getShiftNames();
        },

        watch: {
            'shift.from': {
                handler: function (newD, old) {
                        if (newD) {
                            this.shift.interval=this.getDayname(newD);
                        }
                },
            },
            'shift.to': {
                handler: function(){
                     this.formattedDate();
                }
            }
        },

        methods: {
             formattedDate() {
      const months = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12'
      };

        const preprocessedDate = this.shift.to.replace(/(\d+)-([a-zA-Z]+)-(\d+) (\d+):(\d+) ([APMapm]{2})/, (match, day, month, year, hours, minutes, period) => {
        const monthNumeric = months[month];
        let formattedHours = parseInt(hours);

        if (period === 'PM' && formattedHours !== 12) {
          formattedHours += 12;
        } else if (period === 'AM' && formattedHours === 12) {
          formattedHours = 0;
        }

        return `${year}-${monthNumeric}-${day}T${formattedHours.toString().padStart(2, '0')}:${minutes}`;
      });
      this.shift.to = preprocessedDate;

      const preprocessedDateFrom = this.shift.from.replace(/(\d+)-([a-zA-Z]+)-(\d+) (\d+):(\d+) ([APMapm]{2})/, (match, day, month, year, hours, minutes, period) => {
        const monthNumeric = months[month];
        let formattedHours = parseInt(hours);

        if (period === 'PM' && formattedHours !== 12) {
          formattedHours += 12;
        } else if (period === 'AM' && formattedHours === 12) {
          formattedHours = 0;
        }

        return `${year}-${monthNumeric}-${day}T${formattedHours.toString().padStart(2, '0')}:${minutes}`;
      });
      this.shift.from = preprocessedDateFrom
    },
            getShiftNames()
            {
                this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
                    let data = response.data.payload.paginated;
                    this.shift_names = data;
                }).catch((error) => {
                    this.handleErrorResponse(error.response, "error");
                });
            },
            fetchRoles() {
                this.$axios.get('roles').then((response) => {
                    let data = response.data.payload;
                    this.roles_options = data;
                }).catch((error) => {
                    this.handleErrorResponse(error.response, "error");
                });
            },
            fetchLocations() {
                this.$axios.get("/locations").then((response) => {
                    let data = response.data;
                    this.locations = data.payload;

                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },
            addRole() {
                this.roles.push({
                    id: null,
                    count: null,
                    role_count: null,
                    remaining_slots: null,
                })
            },
            deleteRole(index) {
                this.roles.splice(index, 1);
            },
        },

        middleware: "authentication",
    };
</script>

<template>
    <div>
        <div class="row">
            <b-form>
                <div class="d-flex flex-md-row flex-column gap-3">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <label>Shift Name</label>

                                        <b-input-group >
                                            <select v-model="shift.name" @click="getShiftNames()"  id="shift_name" class="form-select" 
                                                    :class="{ 'is-invalid': $v.shift.name.$error }">
                                                <option :value="null" v-if="shift_names.length == 0" disabled>No Shift Name Found</option>
                                                <option :value="null" v-else disabled>Select Shift Name</option>
                                                <option :value="shift_name.name" v-for="(shift_name, index) in shift_names" :key="index">
                                                    {{ shift_name.name }}
                                                </option>
                                                
                                            </select>
                                            <b-input-group-prepend is-text class="w-25">
                                                 <b-form-input id="color" v-model="shift.label_color" type="color"></b-form-input>
                                            </b-input-group-prepend>
                                            <div
                                                v-if="!$v.shift.name.required"
                                                class="invalid-feedback"
                                                >
                                                Name is required.
                                            </div>
                                        </b-input-group>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="align-items-center d-flex gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-date" label="From" label-for="time">
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.from.$error }" v-if="!shiftFromCalendar"
                                             v-model="shift.from" :min="changeDateTimeToLocalFormats(Date())" type="datetime-local"></b-form-input>
                                        
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.from.$error }"
                                             v-model="shift.from" v-else type="time"></b-form-input>
                                             
                                        <div
                                            v-if="!$v.shift.from.required"
                                            class="invalid-feedback"
                                            >
                                            From is required.
                                        </div>
                                        </b-form-group>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <label>Select Location</label>

                                        <select v-model="shift.location_id" id="emp_job_type" class="form-select"  :class="{ 'is-invalid': $v.shift.location_id.$error }" >
                                            <option :value="null" v-if="locations.length == 0" disabled>No Location Found</option>
                                            <option :value="null" v-else disabled>Select Location</option>
                                            <option :value="location.id" v-for="(location, index) in locations" :key="index">
                                                {{ location.name }}
                                            </option>
                                        </select>
                                        <div
                                            v-if="!$v.shift.location_id.required"
                                            class="invalid-feedback"
                                            >
                                            Location is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="align-items-center d-flex gap-2">
                                    
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-date" label="To" label-for="time" class="mb-3">
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.to.$error }" v-model="shift.to" :min="changeDateTimeToLocalFormats(Date())" max="" v-if="!shiftFromCalendar" type="datetime-local"></b-form-input>
                                            
                                            <b-form-input id="time" :class="{ 'is-invalid': $v.shift.to.$error }" v-model="shift.to" :min="changeDateTimeToLocalFormats(Date())" max="" v-else type="time"></b-form-input>
                                            <div
                                            v-if="!$v.shift.to.required"
                                            class="invalid-feedback"
                                            >
                                                To is required.
                                            </div>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mb-3" v-for="(role, index) in roles" :key="index">
                    <div class="d-grid mb-3 role-grid align-items-end">
                        <div class="form-field__wrapper w-100">

                            <label>Select Role</label>
                            <select v-model="role.id" class="form-select" :disabled="disableToDate">
                                <option :value="null" v-if="roles_options.length == 0" disabled>No Role Found</option>
                                <option :value="null" v-else disabled>Select Role</option>
                                <option :value="role_option.id" v-for="(role_option, index) in roles_options" :key="index">
                                    {{ role_option.name }}
                                </option>
                            </select>
                        </div>
                        <div class="grid-02">
                            <b-form-group id="example-date" label="Count" label-for="count">
                                <b-form-input id="count" v-model="role.count" type="number" required></b-form-input>
                            </b-form-group>
                        </div>
                        <div>
                            <button type="button" class="mt-3 bg-dark btn text-white" @click="deleteRole(index)">Delete</button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                </div>

                <b-form-group>
                    <b-form-checkbox v-model="shift.shift_type" value="Premium" unchecked-value="Non_Premium" class="mb-3" checked plain>Premium</b-form-checkbox>
                </b-form-group>
                <div class="col-md-2 ms-4" v-if="shift.shift_type == 'Premium'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">$</span>
                        <input type="number" v-model="shift.bonus" placeholder="00" class="form-control" aria-label="Amount (to the nearest dollar)" />
                    </div>
                </div>

                 <div class="col-md-12 mb-3">
                    <div class="align-items-center d-flex gap-2">
                    </div>
                </div>

            </b-form>
        </div>
    </div>
</template>
<style scoped>
.input-group-text {
    height: 39px;
}
</style>
